import React, { useState } from 'react';
import axios from 'axios';
import "../Components/style.css";

const MainBlock5 = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    question: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://back-opencv-nodejs-production-05a3.up.railway.app/api/send-email', formData);
      alert('Електронний лист успішно відправлено! 😊');
    } catch (error) {
      alert('Не вдалося відправити лист. Будь ласка, спробуйте знову. 😞');
    }
  };
  return (
    <>
      <h2 className="questionText" id='questions'>У Вас виникли питання?</h2>
      <div className='block5flex'>
          <form className='blockInputLi' onSubmit={handleSubmit}>
            <div className='divInput'>
              <div>
                <input
                  className='input'
                  type="text"
                  placeholder="Ваше ім’я"
                  name="name"
                  autoComplete="username"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  className='input'
                  type="email"
                  placeholder="Ваша електронна пошта"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  className='input'
                  type="text"
                  placeholder="Яке у Вас питання?"
                  name="question"
                  autoComplete="question"
                  value={formData.question}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className='submit'>Відправити</button>
            </div>
          </form>
          <div className='liTexts'>
            <ul>
              <li className='liText'>нова компанія</li>
              <li className='liText2'>нові технології</li>
              <li className='liText3'>передовi технологiї </li>
            </ul>
          </div>
      </div>
      <div className='line2'></div>
      <footer className='footer'>
        <div className='copyright'>Copyright ©2023 ТОВ <strong>“Snake Security”</strong> - Всі права захищенні. Копіювання матеріалів тільки з посиланням на сайт.</div>
            <div className='imageFooter'>
                    <a href="https://www.facebook.com/SNAKESECURIT2325" rel="noopener noreferrer">
                      <img src="\img\facebook.png" alt="" />
                    </a>
                    <a href="https://www.instagram.com/snake_security2325/" rel="noopener noreferrer">
                      <img src="\img\instagram.png" alt="" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCYLiQJWpVPuU3Zm7RVTni7w">
                      <img src="\img\youtube.png" alt="" />
                    </a>
            </div>
      </footer>
    </>
  );
}

export default MainBlock5;
