
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'; 

const firebaseConfig = {
  apiKey: "AIzaSyBqEizg2_V9HGyieAuIVYwDasIx92JII7M",
  authDomain: "snake-security.firebaseapp.com",
  databaseURL: "https://snake-security-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "snake-security",
  storageBucket: "snake-security.appspot.com",
  messagingSenderId: "442257405795",
  appId: "1:442257405795:web:996a3dee0e49d827ad8556",
  measurementId: "G-CPRTBQ3TC6"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);  
const auth = getAuth(app);  

const signInWithGoogle = () => {
  const authProvider = new GoogleAuthProvider();
  signInWithPopup(auth, authProvider)
    .then((result) => {
      const user = result.user;
      console.log('Google Sign-In successful', user);
    })
    .catch((error) => {
      console.log('Google Sign-In error', error.message);
    });
};

export { analytics, database, auth, signInWithGoogle };
