import React from 'react';
import "../Components/style.css"

const MainBlock2 = () => {
    return ( 
        <>
        <h2 className="whyUs">Чому саме ми?</h2>
        <div className='whyUsAbout'>"Snake Security" - ваш надійний партнер у сфері забезпечення безпеки. Ми спеціалізуємося на наданні послуг з виявлення руху за допомогою власного скрипту на основі бібліотеки OpenCV. Ми успішно працюємо у сфері безпеки в Харкові.</div>
        <div className='whyUsBlocks'>
                    <div className='whyUsBlock1'>
                        <div className='ellipseContainer'>
                            <img className='ellipse' src="\img\Ellipse1.png" alt="" />
                            <img className='robot' src="\img\robot.png" alt="" />
                        </div>
                        <p className='whyUsInfo'> Наш ТелеграмБот - це зручний інструмент, що дозволяє контролювати приміщення з будь-якої точки світу. Він може: надсилати відео, увімкнути та вимкнути режим розпізнавання руху, налаштування інтерфейсу та багато іншого. </p>
                    </div>
                    <div className='whyUsBlock2'>
                        <div className='ellipseContainer'>
                            <img className='ellipse' src="\img\Ellipse1.png" alt="" />
                            <img className='eye' src="\img\eye.png" alt="" />
                        </div>
                            <p className='whyUsInfo1'>Наша власна програма розпізнавання руху, розроблена на мові програмування Python. Ми використовуємо передові технології для забезпечення вашої безпеки та спокою.</p>
                    </div>
                    <div className='whyUsBlock3'>
                        <div className='ellipseContainer'>
                            <img className='ellipse' src="\img\Ellipse1.png" alt="" />
                            <img className='support' src="\img\support.png" alt="" />
                        </div>
                            <p className='whyUsInfo2'>Ми готові відповідати на ваші запитання цілодобово, сім днів на тиждень. Пишіть або телефонуйте нам, ми з радістю надамо відповіді на всі ваші питання.</p>
                    </div>
                    <div className='whyUsBlock4'>
                        <div className='ellipseContainer'>
                            <img className='ellipse' src="\img\Ellipse1.png" alt="" />
                            <img className='house' src="\img\house.png" alt="" />
                        </div>
                            <p className='whyUsInfo3'>Ми дбаємо про ваш час, забезпечуючи зручний і швидкий контроль за режимом розпізнавання руху. Економте свій час та не нехтуйте безпекою!</p>
                    </div>
        </div>
        </>
     );
}
 
export default MainBlock2;
