import React, { useState } from 'react';
import { signInWithGoogle } from '../Components/FireBase/auth'; 
import "../Components/style.css";
import '../font/font.css'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="header">
          <div className={`burger-menu ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
          </div>
          <div className={`menu ${isOpen ? 'open' : ''}`}>
              <div className="singUp" onClick={signInWithGoogle}>Авторизація</div>
                <div className="Price" onClick={() => scrollToSection('price')}>Послуги</div>
                <div className="Questions" onClick={() => scrollToSection('questions')}>Питання</div>
              <div className="Camera" onClick={() => scrollToSection('camera')}>Камера</div>
          </div>
      </div>
      <div className='mainBlock'>
          <div>
            <img className='mainSnake' src="\img\mainSnake.png" alt="" />
          </div>
          <div>
            <div className='pSnake'>Snake</div>
            <p className='pSecurity'>Security</p>
          </div>
      </div>
      <div className='mainButtons'>
        <a href='tel:+380634149231' className='Call'>Замовити дзвінок</a>
        <a href='https://t.me/young2325' className='writeToUs'>Написати нам</a>
      </div>
    </>
  );
};

export default Header;
