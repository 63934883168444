import React from 'react';
import Header from './Components/Header'; 
import "../src/index.css"
import MainBlock2 from './Components/MainBlock2';
import MainBlock3 from './Components/MainBlock3';
import MainBlock4 from './Components/MainBlock4';
import MainBlock5 from './Components/MainBlock5';


const App = () => {
  return (
    <div>
      <Header/>
      <MainBlock2/>
      <MainBlock3/>
      <MainBlock4/>
      <MainBlock5/>
    </div>
  );
};

export default App;
