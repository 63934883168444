import React, { useState } from 'react';
import "../Components/style.css"



const MainBlock4 = () => {

    const [expanded, setExpanded] = useState(false);
    const [expanded2, setExpanded2] = useState(false);
    const [expanded3, setExpanded3] = useState(false);

    const handleMoreClick = () => {
      setExpanded(!expanded);
    };

    const handleMoreClick2 = () => {
        setExpanded2(!expanded2);
    };

    const handleMoreClick3 = () => {
        setExpanded3(!expanded3);
    };

    const getButtonStyles = (expandedState) => ({
      height: expandedState ? '150px' : '117px',
      transition: 'height 0.3s ease'
    });

    const getButtonStyles1 = (expandedState1) => ({
      height: expandedState1 ? '150px' : '117px',
      transition: 'height 0.3s ease',
      borderRadius: '21px',
      background: '#1B58B3',
      boxShadow: '3px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
    });

    const getButtonStyles2 = (expandedState2) => ({
      height: expandedState2 ? '150px' : '117px', 
      transition: 'height 0.3s ease',
      borderRadius: '21px',
      background: '#2D1C8D',
      boxShadow: '3px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
    });
    

    const priceListBasicStyles = {
        width: '1304px',
        height: expanded ? '326px' : 'auto', 
        flexShrink: 0,
        borderRadius: '31px',
        border: '7px solid #0BB1FF',
        boxShadow: '6px 6px 5px 0px rgba(0, 0, 0, 0.25) inset',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-around',
        padding: '50px 0px',
        margin: '70px auto',
        transition: 'height 1s ease', 
      };

      const priceListStandartStyles = {
        width: '1304px',
        height: expanded2 ? '326px' : 'auto',
        flexShrink: 0,
        borderRadius: '31px',
        border: '7px solid #226DDE',
        boxShadow: '6px 6px 5px 0px rgba(0, 0, 0, 0.25) inset',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-around',
        padding: '50px 0px',
        margin: '70px auto',
        transition: 'height 0.3s ease',
      };

      const priceListPremiumStyles = {
        width: '1304px',
        height: expanded3 ? '326px' : 'auto', 
        flexShrink: 0,
        borderRadius: '31px',
        border: '7px solid #3C25BB',
        boxShadow: '6px 6px 5px 0px rgba(0, 0, 0, 0.25) inset',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-around',
        padding: '50px 0px',
        margin: '70px auto',
        transition: 'height 0.3s ease', 
      };

      const additionalText = `
    🤖Телеграм Бот із Розпізнаванням Руху: Слідкуйте за подіями у реальному часі через ваш обраний месенджер.
    🔐Зміна Логіну та Паролю: Додатковий рівень безпеки на ваш вибір.
    🌐Веб-сайт для Стрімінгу: Переглядайте стріми з будь-якої точки світу.✨
  `;

    const additionalText2 = `
    🔔Підтримка 24/7: Ваш надійний захисник цілодобово!
    🆓 Пробний період 3 дні: Тестуйте без зобов'язань!
    🔊 Запис звуку: Зберігайте важливі моменти для додаткового контролю!
    💼 Вигідно, безпечно та зручно! #Стандарт #Безпека24/7 #ТестПеріод 🚀
   `;
      
    const additionalText3 = `
    🎙️ Запис на Облако: Зберігайте дані безпечно та доступно завжди!
    💡 Реалізуйте свої ідеї: Ми відкриті до вашого внеску! Запропонуйте власні ідеї, і ми втілимо їх в життя.
    💼 Преміум - це більше, ніж лише послуга, це спільне творчество! #Преміум #ІдеїВЖиття🚀
   `;
    
    return ( 
        <>
        <h2 className="priceList" id='price'>Прайс-лист</h2>
        <div className='priceListBasic' style={priceListBasicStyles}>
                <div className='basic'>Базовий</div>
                    <div className='basicInfo'>
                          <div className='basicInfoText'>
                              <span style={{ color: '#8CDAFF' }}>Базовий</span> пакет з мінімальними потребами
                          </div>
                    </div>
                    <div className='price'>
                            <div className='priceYear'>
                                <div className='year'>Річна</div>
                                <div className='numderPrice'>8200</div>
                            </div>
                                <div className='line'></div>
                            <div>
                                <div className='month'>Місячна</div>
                                <div className='numderPrice'>800</div>
                            </div>
                    </div>
                    <div style={{ marginTop:'17px'}}><img src="\img\snake.png" alt="" /></div>
                <div className='more' onClick={handleMoreClick} style={getButtonStyles(expanded)}>
                      {expanded ? (
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" height="41" viewBox="0 -960 960 960" width="41"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                      </>
                    ) : (
                      <>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="41"  height="41"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="1.5"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-circle-arrow-down"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M8 12l4 4" /><path d="M12 8v8" /><path d="M16 12l-4 4" /></svg>
                      </>
                    )} 
                </div>
        </div>
        {expanded && (
        <div className='additionalText' style={{ color: 'white', width: '1040px', height:'145px', fontSize:'25px', fontWeight:'400', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', marginTop:'-250px', zIndex:'3' }}>
          {additionalText}
        </div>
      )}

        <div className='priceListStandart' style={priceListStandartStyles}>
            <div className='standart'>Стандарт</div>
                  <div className='standartInfo'>
                      <div className='standartInfoText'>
                        <span style={{ color: '#7CB0FF' }}>Середній</span> пакет з розширеними можливостями
                      </div>
                  </div>
                    <div className='price2'>
                            <div className='priceYear'>
                                    <div className='year'>Річна</div>
                                    <div className='numderPrice'>9400</div>
                            </div>
                            <div className='line'></div>
                            <div>
                                <div className='month'>Місячна</div>
                                <div className='numderPrice'>900</div>
                            </div>
                    </div>
                    <div style={{ marginTop:'17px'}}><img src="\img\snake.png" alt="" /></div>
                    <div className='more' onClick={handleMoreClick2} style={getButtonStyles1(expanded2)}>
                                {expanded2 ? (
                                <>
                                  <svg xmlns="http://www.w3.org/2000/svg" height="41" viewBox="0 -960 960 960" width="41"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                                </>
                              ) : (
                                <>
                                  <svg  xmlns="http://www.w3.org/2000/svg"  width="41"  height="41"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="1.5"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-circle-arrow-down"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M8 12l4 4" /><path d="M12 8v8" /><path d="M16 12l-4 4" /></svg>
                                </>
                              )}
                      </div>
        </div>
        {expanded2 && (
        <div className='additionalText2' style={{ color: 'white', width: '1040px', height:'145px', fontSize:'25px', fontWeight:'400', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', marginTop:'-240px', zIndex:'3' }}>
          {additionalText2}
        </div>
      )}
        <div className='priceListPremium' style={priceListPremiumStyles}>
                  <div className='premium'>Преміум</div>
                      <div className='premiumInfo'>
                          <div className='premiumInfoText'>
                              <span style={{ color: '#6F55FF' }}>Повний</span> пакет з усіма зручностями
                          </div>
                      </div>
                      <div className='price3'>
                            <div className='priceYear'>
                                    <div className='year'>Річна</div>
                                    <div className='numderPrice'>10500</div>
                            </div>
                                <div className='line'></div>
                                <div>
                                    <div className='month'>Місячна</div>
                                    <div className='numderPrice'>1150</div>
                                </div>
                      </div>
                                <div style={{ marginTop:'17px'}}><img src="\img\snake.png" alt="" /></div>
                      <div className='more' onClick={handleMoreClick3} style={getButtonStyles2(expanded3)}>
                              {expanded3 ? (
                              <>
                                <svg xmlns="http://www.w3.org/2000/svg" height="41" viewBox="0 -960 960 960" width="41"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                              </>
                            ) : (
                              <>
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="41"  height="41"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="1.5"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-circle-arrow-down"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M8 12l4 4" /><path d="M12 8v8" /><path d="M16 12l-4 4" /></svg>
                              </>
                            )}
                      </div>
        </div>
        {expanded3 && (
        <div className='additionalText3' style={{ color: 'white', width: '1043px', height:'145px', fontSize:'25px', fontWeight:'400', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', marginTop:'-250px', zIndex:'3' }}>
          {additionalText3}
        </div>
      )}
        </>
     );
}
 
export default MainBlock4;
