import React from 'react';
import "../Components/style.css"

const MainBlock3 = () => {
    return ( 
        <>
        <div className='mainBlockContent'>
            <img src="\img\ipcamera.png" alt="" />
            <div id='camera' className='mainBlockInfo'>Унікальність полягає в розширеній функціональності IP-камер. Тепер вони володіють функцією <span className='highlight motionDetection'> розпізнавання руху </span> та <span className='highlight telegramBot'>відправкою відео в Telegram-бот</span>.<br /><br /> Маєте свої уподобання? Виберіть камеру <span className='highlight chooseCamera'>самостійно</span>. Потрібна консультація? Просто <span className='highlight callUs'><a href="tel:+380634149231" style={{ color: 'inherit', textDecoration:'none'}}>зателефонуйте нам</a></span>, і ми допоможемо вам вибрати оптимальний варіант для ваших потреб.</div>
        </div>
        </>
     );
}
 
export default MainBlock3;
